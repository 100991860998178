<template>
  <div class="flex-row flex-center " style="padding: 10px; flex-wrap: wrap;">
    <carddoc class="card-doc-box" v-for="(item, index) in docList" :key="index" :title="item.title" :url="item.url"
      :icon="item.icon">
    </carddoc>
  </div>
</template>

<script>
import carddoc from '../components/carddocComponent.vue'
export default {
  components: {
    carddoc
  },
  data() {
    return {
      docList: [
        {
          title: '菜鸟网站',
          url: 'https://www.runoob.com/',
          icon: 'https://ts1.cn.mm.bing.net/th?id=ODLS.cc1244f4-c576-40dc-8423-06895c0f5444&w=32&h=32&qlt=90&pcl=fffffa&o=6&pid=1.2'
        },
        {
          title: "LeetCode",
          url: "https://leetcode.com/",
          icon: "https://leetcode.com/favicon.ico"
        },
        {
          title: "edX",
          url: "https://www.edx.org/",
          icon: "https://www.edx.org/favicon.ico"
        },
        {
          title: "Coursera",
          url: "https://www.coursera.org/",
          icon: "https://www.coursera.org/favicon.ico"
        },
        {
          title: "Sitepoint",
          url: "https://www.sitepoint.com/",
          icon: "https://www.sitepoint.com/favicon.ico"
        },
        {
          title: "Learnpython",
          url: "https://www.learnpython.org/",
          icon: "https://www.learnpython.org/favicon.ico"
        },
        {
          title: "W3schools",
          url: "https://www.w3schools.com/",
          icon: "https://www.w3schools.com/favicon.ico"
        },
        {
          title: "慕课网",
          url: "https://www.imooc.com/",
          icon: "https://www.imooc.com/favicon.ico"
        },
        {
          title: "GitHub",
          url: "https://github.com/",
          icon: "https://github.com/favicon.ico"
        },
        {
          title: "CSDN",
          url: "https://www.csdn.net/",
          icon: "https://www.csdn.net/favicon.ico"
        }
      ]
    }
  }
}
</script>

<style scoped>
.card-doc-box {
  width: 200px;
  height: 80px;
}
</style>