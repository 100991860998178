<template>
  <div class="flex-row course-list">
    <courseItem v-for="(item, index) in courseList" :key="index" :title="item.title" :description="item.description"
      :img-url="item.img" :createor="item.creator" :video-url="item.videoUrl"></courseItem>
  </div>
</template>

<script>
import courseItem from '../components/courseItemComponent.vue'
export default {
  components: {
    courseItem
  },
  data() {
    return {
      courseList: [
        {
          title: "html5 +css3+前端项目视频教程",
          description: "前端开发入门教程，web前端零基础html5 +css3+前端项目视频教程",
          img: "/HTML+CSS.avif",
          creator: "黑马程序员",
          videoUrl: "https://www.bilibili.com/video/BV1Kg411T7t9?t=0.6"
        },
        {
          title: "JavaScript入门到精通全套视频教程",
          description: "黑马程序员前端JavaScript入门到精通全套视频教程，javascript核心进阶ES6语法、API、js高级等基础知识和实战教程",
          img: "/JS.avif",
          creator: "黑马程序员",
          videoUrl: "https://www.bilibili.com/video/BV1Y84y1L7Nn?t=1.0"
        },
        {
          title: "Vue2+Vue3基础入门到实战项目全套教程",
          description: "前端最新Vue2+Vue3基础入门到实战项目全套教程，自学前端vue就选黑马程序员，一套全通关！",
          img: "/Vue.avif",
          creator: "黑马程序员",
          videoUrl: "https://www.bilibili.com/video/BV1HV4y1a7n4?t=0.8"
        },
        {
          title: "React18入门到实战视频教程",
          description: "黑马程序员前端React18入门到实战视频教程，从react+hooks核心基础到企业级项目开发实战（B站评论、极客园项目等）及大厂面试全通关",
          img: "/React.avif",
          creator: "黑马程序员",
          videoUrl: "https://www.bilibili.com/video/BV1ZB4y1Z7o8?t=45.7"
        }
      ]
    }
  },
  mounted() {

  }
}
</script>

<style scoped>
.course-list {
  flex-wrap: wrap;
}
</style>