<template>
  <div style="padding: 10px;">
    <fileItem></fileItem>
  </div>
</template>

<script>
import fileComponent from '../components/fileComponent.vue'
export default {
  components: {
    fileItem: fileComponent
  },
  data() {
    return {}
  },

}
</script>

<style scoped></style>