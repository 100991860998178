<template>
  <a class="base-box flex-row flex-center  pad-5px" :href="url" target="_blank">
    <img :src="icon" class="icon-box">
    <div style="font-weight: 600; font-size: 22px">{{ title }}</div>
  </a>
</template>

<script>
export default {
  props: {
    title: String,
    url: String,
    icon: String
  },
  created() {
  }
}
</script>

<style scoped>
a {
  color: black;
}

.base-box {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: white;
  transition-property: all !important;
  transition-duration: .5s !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  margin: 5px;
}

.base-box:hover {
  box-shadow: 0px 2px 20px -6px rgba(0, 0, 0, 0.3);
  transform: translateY(-5px);
}

.icon-box {
  height: 40px;
  width: 40px;
}
</style>