<template>
  <div class="flex-col ">
    <pre class="mermaid pathChart flex-row flex-align-center">
  graph LR
      A[前端学习路线] --- B[第一阶段：前端基础入门]
      A --- C[第二阶段：前端技术进阶]
      A --- D[第三阶段 VUE开发]
      A --- E[第四阶段 React前端高阶]
      B --- F[HTML+CSS]
      C --- C1[JavaScript 基础语法]
      C --- C2[JavaScript核心webAPI]
      C --- C3[JavaScript进阶ES6到JS高级]
      C --- C4[数据交互与异步编程-前端进阶Ajax零基础入门]

    </pre>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import mermaid from "mermaid"
export default {
  mounted() {
    mermaid.run()
  },
  methods: {

  }
}
</script>

<style scoped>
.pathChart {
  height: 90vh;
  width: 90vw;
}
</style>