<template>
  <a-layout style="height: 100vh;">
    <a-layout-header class="flex-row flex-center title-text" @click="handle">Web在线学习平台</a-layout-header>
    <a-layout>
      <a-layout-sider theme="dark" style="padding-top: 50px;">
        <div class="flex-col flex-center text-center ">
          <router-link class="pad-10px menu-text" v-for="(item, index) in menuList" :key="index" :to="item.url">{{
            item.title
          }}</router-link>
        </div>
      </a-layout-sider>
      <a-layout-content style="overflow: auto; background-color: #F5F7FA;">
        <router-view></router-view>
      </a-layout-content>
    </a-layout>
    <a-layout-footer class="info-text flex-row flex-center">打造边看边练的Web学习平台</a-layout-footer>
  </a-layout>
</template>

<script>
import menuList from './MenuList.json'
export default {
  components: {},
  data() {
    return {
      menuList: menuList
    }
  },
  methods: {
  }
}
</script>

<style scoped>
/* 全局样式 */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

* {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
</style>