<template>
  <div class="courseBox flex-col flex-center" @click="handleView">
    <div class="course-img-box">
      <img :src="imgUrl" class="course-img">
    </div>
    <div class="course-title pad-5px">{{ title }}</div>
    <div class="course-description pad-5px">{{ description }}</div>
    <div class="course-creator pad-5px">{{ createor }}</div>
  </div>
</template>

<script>

export default {
  props: {
    imgUrl: String,
    title: String,
    description: String,
    createor: String,
    videoUrl: String
  },
  methods: {
    handleView() {
      this.$router.push({ name: 'studyCodePage', params: { videoUrl: this.videoUrl } })
    }
  }
}
</script>

<style scoped>
.courseBox {
  display: flex;
  align-items: center;
  padding: 8px;
  width: 20vw;
  height: 35vh;
  box-shadow: 0px 2px 20px -6px rgba(0, 0, 0, 0.3);
  margin: 10px 0 0 10px;
  border-radius: 10px;
  transition: transform 0.3s ease, transform 0.3s ease;
}

.courseBox:hover {
  cursor: pointer;
  transform: translateY(-5px);
  /* 向上轻微移动 */
}

.course-img-box {
  width: 90%;
  height: 50%;
  padding: 5px;
}

.course-img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.course-title {
  font-size: 18px;
  font-weight: 600;
}

.course-description {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.course-creator {
  font-size: 18px;
  font-weight: 600;
}
</style>