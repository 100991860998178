<template>
  <div class="flex-col " style="overflow: auto;">
    <div class="editorBox">
      <a-split direction="vertical" :style="{
        height: '100vh',
        width: '100%',
        minWidth: '500px',
        border: '1px solid var(--color-border)'

      }" v-model:size="size" min="80px">
        <!-- 代码展示区 -->
        <template #first>
          <div>
            <a-typography-paragraph>
              <iframe ref="showBox" id="showBox" sandbox="allow-same-origin allow-modals allow-scripts"></iframe>
            </a-typography-paragraph>
          </div>
        </template>
        <!-- 代码区 -->
        <template #second>
          <div class="flex-row">
            <a-button style="margin-left: 5vw" @click="loadHtml2Show" id="btn">载入</a-button>
            <a-popconfirm content="这次保存将会覆盖上次保存的代码，是否保存？" ok-text="保存" @ok="saveCode">
              <a-button>保存代码</a-button>
            </a-popconfirm>
            <!-- <button @click="saveCode"></button> -->
            <a-popconfirm content="恢复代码将会覆盖编辑器的代码，是否继续？" ok-text="继续" @ok="getCode">
              <a-button>恢复上次保存的代码</a-button>
            </a-popconfirm>
            <a-button @click="handleFull">全屏观看视频</a-button>
            <a-button @click="handleSaveFile">保存代码到文件</a-button>
            <span class="flex-col" style=" margin: 3px; justify-content: center;">更改背景颜色：</span><a-color-picker
              v-model="colorVal" @change="changeBack" />
            <a-select style="width: 150px;" placeholder="16px" ref="changeFont" id="changeFont" :model-value="codeSize"
              @change="changeEditorFont">
              <a-option value="16" label="16px"></a-option>
              <a-option value="20" label="20px"></a-option>
              <a-option value="24" label="24px"></a-option>
              <a-option value="28" label="28px"></a-option>
            </a-select>
          </div>
          <a-typography-paragraph>
            <div>
              <a-split :style="{
                height: '100vh',
                width: '100%',
                minWidth: '500px',
                border: '1px solid var(--color-border)'
              }" min="80px">
                <template #first>
                  <a-typography-paragraph>
                    <div style="height: 1920px">
                      <div ref="htmlBox" id="htmlBox" class="itemBox"></div>
                    </div>
                  </a-typography-paragraph>
                </template>
                <template #second>
                  <div>
                    <a-split direction="vertical" :style="{ height: '50vh' }">
                      <template #first>
                        <a-typography-paragraph>
                          <div style="height: 300px; overflow: scroll;" class="flex-col">
                            <div ref="cssBox" id="cssBox" class="itemBox"></div>
                          </div>
                        </a-typography-paragraph>
                      </template>
                      <template #second>
                        <a-typography-paragraph>
                          <div style="height: 1920px;">
                            <div ref="jsBox" id="jsBox" class="itemBox"></div>
                          </div>
                        </a-typography-paragraph>
                      </template>
                    </a-split>
                  </div>
                </template>
              </a-split>
            </div>
          </a-typography-paragraph>
        </template>
      </a-split>
    </div>
    <!-- 视频 -->
    <iframe ref="fullScreen" :src="videoUrl" class="videoBox"></iframe>
  </div>


</template>

<script>
import * as monaco from 'monaco-editor'
import { Modal } from '@arco-design/web-vue';
import { toRaw } from 'vue'
export default {
  data() {
    return {
      codeSize: 16,
      colorVal: 'FFFFFF',
      htmlEditor: null,
      cssEditor: null,
      jsEditor: null,
      size: '300px',
      videoUrl: 'https://www.bilibili.com/video/BV14J4114768?t=1.5'
    }
  },
  methods: {
    handleSaveFile() {
      let iframe = this.$refs.showBox;
      let HTML = iframe.contentWindow.document.documentElement.outerHTML;
      let a = document.createElement('a')
      a.href = window.URL.createObjectURL(new Blob([HTML], { type: 'text/html' }))
      a.download = 'yourHTML.html'
      a.click()
      window.URL.revokeObjectURL(a.href)
    },
    toggleFullScreen(iframe) {
      if (!document.fullscreenElement && !document.mozFullScreenElement &&
        !document.webkitFullscreenElement && !document.msFullscreenElement) {
        if (iframe.requestFullscreen) {
          iframe.requestFullscreen();
        } else if (iframe.msRequestFullscreen) {
          iframe.msRequestFullscreen();
        } else if (iframe.mozRequestFullScreen) {
          iframe.mozRequestFullScreen();
        } else if (iframe.webkitRequestFullscreen) {
          iframe.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      }
    },
    handleFull() {
      this.toggleFullScreen(this.$refs.fullScreen)
    },
    getCode() {
      let htmlText = localStorage.getItem('htmlText')

      let jsText = localStorage.getItem('jsText')

      let cssText = localStorage.getItem('cssText')
      if (!htmlText && !jsText && !cssText) {
        this.$notification.warning('你上次没有保存代码哦~')
        return
      }

      let iframe = this.$refs.showBox.contentDoucment || this.$refs.showBox.contentWindow.document

      if (!iframe) {
        alert("展示出错啦~")
        return
      }

      iframe.body.innerHTML = htmlText

      let style = iframe.createElement('style')
      style.type = 'text/css'
      style.innerHTML = cssText
      iframe.body.appendChild(style)
      let script = iframe.createElement('script')
      script.type = 'text/javascript'
      /* 如果不把用户输入的代码块放入一个函数中，
      即使清除了iframe中原有的script标签，放入一个新的js标签，上一次js标签中创建的变量还是会遗留 
      因此采取下面这种解决方法
      */
      let functionName = "A" + Date.now() + parseInt(Math.random() * 10000)
      script.text = "function " + functionName + "(){" + jsText + "}" + functionName + "()"
      iframe.body.appendChild(script)
      toRaw(this.jsEditor).setValue(jsText)
      toRaw(this.htmlEditor).setValue(htmlText)
      toRaw(this.cssEditor).setValue(cssText)
      this.$notification.success('恢复成功！')
    },
    saveCode() {
      let htmlText = toRaw(this.htmlEditor).getValue()

      let jsText = toRaw(this.jsEditor).getValue()

      let cssText = toRaw(this.cssEditor).getValue()
      localStorage.setItem('htmlText', htmlText)
      localStorage.setItem('cssText', cssText)
      localStorage.setItem('jsText', jsText)
      this.$notification.success('保存代码成功！')
    },
    changeBack() {
      this.$refs.showBox.style.backgroundColor = this.colorVal
    },
    loadHtml2Show() {
      let htmlText = toRaw(this.htmlEditor).getValue()

      let jsText = toRaw(this.jsEditor).getValue()

      let cssText = toRaw(this.cssEditor).getValue()

      let iframe = this.$refs.showBox.contentDoucment || this.$refs.showBox.contentWindow.document

      if (!iframe) {
        alert("展示出错啦~")
        return
      }

      iframe.body.innerHTML = htmlText

      let style = iframe.createElement('style')
      style.type = 'text/css'
      style.innerHTML = cssText
      iframe.body.appendChild(style)
      let script = iframe.createElement('script')
      script.type = 'text/javascript'
      /* 如果不把用户输入的代码块放入一个函数中，
      即使清除了iframe中原有的script标签，放入一个新的js标签，上一次js标签中创建的变量还是会遗留 
      因此采取下面这种解决方法
      */
      let functionName = "A" + Date.now() + parseInt(Math.random() * 10000)
      script.text = "function " + functionName + "(){" + jsText + "}" + functionName + "()"
      iframe.body.appendChild(script)
    },
    initEditor() {
      this.$nextTick(() => {

        this.htmlEditor = monaco.editor.create(document.getElementById('htmlBox'), {
          value: [
            '<div> Hellow World</div>',
          ].join('\n'),
          language: 'html',
          theme: 'vs-light', // 默认使用 'vs-dark' 主题
          automaticLayout: true, // 启用自动布局

        })
        this.cssEditor = monaco.editor.create(document.getElementById('cssBox'), {
          value: [
            '*{',
            '\tpadding: 0;',
            '}'
          ].join('\n'),
          language: 'css',
          theme: "hc-black", // 默认使用 'vs-dark' 主题
          automaticLayout: true, // 启用自动布局
        })
        this.jsEditor = monaco.editor.create(document.getElementById('jsBox'), {
          value: [
            'function x() {',
            '\tconsole.log("Hello world!");',
            '}'
          ].join('\n'),
          language: 'javascript',
          theme: 'vs-dark', // 默认使用 'vs-dark' 主题
          automaticLayout: true, // 启用自动布局
        });
      })
    },
    changeEditorFont(value) {
      this.codeSize = value
      this.htmlEditor.updateOptions({ fontSize: this.codeSize });
      this.cssEditor.updateOptions({ fontSize: this.codeSize });
      this.jsEditor.updateOptions({ fontSize: this.codeSize });
    },
  },
  created() {
    this.initEditor()
    if (!this.$route.params.videoUrl) {
      this.$notification.warning("您没有选择视频课程，已你加载默认的视频~")
    }
    else {
      this.videoUrl = this.$route.params.videoUrl
      this.$notification.info({
        title: "学习提醒~",
        content: "1、可以开启B站视频的画中画功能或者全屏观看效果更佳哦！\n\n2、写完代码可以点击《载入》按钮展示效果哦！",
        duration: 15000,
        closable: true
      })
    }
  },
  beforeRouteLeave(to, from, next) {
    Modal.info({
      title: '温馨提示',
      content: '离开此页面将清空编辑区代码，是否离开此页面？',
      hideCancel: false,
      onOk() {
        next()
      },
      onCancel() {
        return
      }
    },

    )
  }
}

</script>

<style scoped>
.editorBox {
  width: 100vw;
  height: 70vh;
}

.videoBox {
  width: 100vw;
  height: 50vh;
  z-index: 100 !important;
}

#showBox {
  width: 100%;
  height: 1920px;
  background-color: white;
}

.itemBox {
  display: inline-block;
  width: 100%;
  height: 100%;
  margin-right: 20px;
}
</style>