<template>
  <!-- 遮罩层 -->

  <div class="overlay" ref="overlay">
    <div class="loadingTitle">Web在线编程</div>
    <div class="loaderBox">
      <div class="loader"></div>
    </div>
    <!-- 开始按钮 -->
    <button class="shadow__btn start-button" @click="canStart"><span>开始</span></button>

  </div>
</template>

<script>
export default {
  methods: {
    canStart() {
      this.$refs.overlay.style.display = "none"
      this.$emit('startChange', true)
    }
  }
}
</script>

<style scoped>
/*罩层样式 */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #212121;
  /* 半透明黑色背景 */
  z-index: 1000;
  /* 确保遮罩层在顶部 */

  /* 默认不显示 */
}

/* 按钮样式 */
.start-button {
  position: absolute;
  left: 50%;
  top: 55%;
}

/* 加载动画 */

.loaderBox {
  position: absolute;
  left: 55%;
  top: 40%;
}

.loader {
  position: relative;
  width: 120px;
  height: 90px;
  margin: 0 auto;
}

.loader:before {
  content: "";
  position: absolute;
  bottom: 30px;
  left: 50px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: #2a9d8f;
  animation: loading-bounce 0.5s ease-in-out infinite alternate;
}

.loader:after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 7px;
  width: 45px;
  border-radius: 4px;
  box-shadow: 0 5px 0 #f2f2f2, -35px 50px 0 #f2f2f2, -70px 95px 0 #f2f2f2;
  animation: loading-step 1s ease-in-out infinite;
}

@keyframes loading-bounce {
  0% {
    transform: scale(1, 0.7);
  }

  40% {
    transform: scale(0.8, 1.2);
  }

  60% {
    transform: scale(1, 1);
  }

  100% {
    bottom: 140px;
  }
}

@keyframes loading-step {
  0% {
    box-shadow: 0 10px 0 rgba(0, 0, 0, 0),
      0 10px 0 #f2f2f2,
      -35px 50px 0 #f2f2f2,
      -70px 90px 0 #f2f2f2;
  }

  100% {
    box-shadow: 0 10px 0 #f2f2f2,
      -35px 50px 0 #f2f2f2,
      -70px 90px 0 #f2f2f2,
      -70px 90px 0 rgba(0, 0, 0, 0);
  }
}

.loadingTitle {
  position: absolute;
  display: inline-block;
  left: 30%;
  top: 45%;
  color: white;
  font-size: 40px;

}

/* 主页面按钮 */
.shadow__btn {
  padding: 10px 20px;
  border: none;
  font-size: 17px;
  color: #fff;
  border-radius: 7px;
  letter-spacing: 4px;
  font-weight: 700;
  transition: 0.5s;
  transition-property: box-shadow;
  z-index: 10;
}

.shadow__btn {
  background: rgb(0, 140, 255);
  box-shadow: 0 0 25px rgb(0, 140, 255);
}

.shadow__btn:hover {
  box-shadow: 0 0 5px rgb(0, 140, 255),
    0 0 25px rgb(0, 140, 255),
    0 0 50px rgb(0, 140, 255),
    0 0 100px rgb(0, 140, 255);
}
</style>